import { useRouter } from "next/router";
import styles from "./SIgninStyles.module.css";

export default function EmailSignin() {
  const router = useRouter();
  return (
    <div onClick={() => router.push("/app/email")} className={styles.main}>
      <div className={styles.iconDIv}>
        <svg
          width="21"
          height="19"
          viewBox="0 0 21 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.30008 18.49H16.7001C18.755 18.49 20.4201 16.825 20.4201 14.77V4.23001C20.4201 2.17505 18.755 0.51001 16.7001 0.51001H4.30008C2.24512 0.51001 0.580078 2.17505 0.580078 4.23001V14.77C0.580078 16.825 2.24512 18.49 4.30008 18.49ZM3.06008 4.23001C3.06008 3.54463 3.6147 2.99001 4.30008 2.99001H16.7001C17.0295 2.99001 17.3443 3.12079 17.5768 3.3533C17.8093 3.58581 17.9401 3.90064 17.9401 4.23001V4.78826L12.0284 9.01077C11.1105 9.65135 9.88987 9.65135 8.97196 9.01077L3.06026 4.78826L3.06008 4.23001ZM3.06008 7.835L7.5309 11.0283C8.39673 11.6471 9.43569 11.9801 10.5001 11.9801C11.5645 11.9801 12.6035 11.6471 13.4693 11.0283L17.9401 7.835V14.77C17.9401 15.0994 17.8094 15.4142 17.5769 15.6467C17.3443 15.8792 17.0295 16.01 16.7001 16.01H4.30014C3.61476 16.01 3.06014 15.4554 3.06014 14.77L3.06008 7.835Z"
            fill="#FFFFF2"
          />
        </svg>
      </div>
      <h5 className={styles.signinText}>Sign in with email</h5>
    </div>
  );
}
